@import "../colors.scss";
@import "../mixins.scss";
@import "../typography.scss";
.home {
  .teaser {
    @extend %flex-column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      width: 100%;
    }
    .section-1 {
      @extend %flex-column;
      justify-content: space-around;
      padding: 25px;
      background-color: $layoutDarkBlue;
      @media screen and (min-width: 768px) {
        width: 40%;
        padding: 40px;
      }
      p {
        color: white;
      }
      .header {
        font-family: $headingFont;
        font-weight: bold;
        font-size: 26px;
        line-height: 1.4;
        @media screen and (min-width: 991px) {
          font-size: 34px;
        }
      }
      .subtext {
        padding: 18px 0px;
        font-size: 16px;
        @media screen and (min-width: 991px) {
          font-size: 24px;
        }
      }
      a {
        align-self: center;
      }
      @media screen and (min-width: 991px) {
        button {
          font-size: 18px;
        }
      }
    }
    .section-2 {
      img {
        width: 100%;
        height: 100%;
      }
      @media screen and (min-width: 768px) {
        width: 60%;
      }
      @media screen and (min-width: 991px) {
        display: flex;
        flex-grow: 1;
        min-height: unset;
        min-width: unset;
      }
    }
  }
  .intro {
    @extend %background-topography;
  }
  .new-addition {
    background-color: $layoutWhite;
    p {
      font-weight: bolder;
    }
    .section-1 {
      width: 100%;
      @extend %flex-column;
      justify-content: around;
      @media screen and (min-width: 768px) {
        width: 75%;
        margin: 50px 0px;
      }
    }
    .section-2 {
      display: none;
      @media screen and (min-width: 768px) {
        display: unset;
        align-self: center;
        width: 25%;
        margin-left: 50px;
      }
      img {
        width: 100%;
      }
    }
  }
  .mission {
    background-image: url("../../assets/ourMission.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (min-width: 768px) {
      background-attachment: fixed;
      height: 600px;
    }
    p,
    h2,
    svg {
      color: $layoutWhite;
    }
    .layer {
      @extend %flex-centered;
      height: 100%;
      width: 100%;
      background-color: rgba(138, 127, 115, 0.7);
    }
  }
  .intro,
  .mission,
  .new-addition {
    p {
      margin-top: 40px;
      font-size: 16px;
    }
  }
  .mission,
  .new-addition {
    a {
      margin-top: 35px;
      align-self: center;
      @media screen and (min-width: 768px) {
        align-self: unset;
      }
    }
    @media screen and (min-width: 768px) {
      button {
        padding: 25px;
        font-size: 22px;
      }
    }
  }
  .content-container {
    @extend %flex-centered;
  }
  .content-block {
    @extend %container;
    @extend %flex-column;
    width: 100%;
    margin: 30px 0px;
    padding: 20px;
    border-radius: 5px;
    justify-content: space-between;
    align-self: center;
    @media screen and (min-width: 768px) {
      margin: 100px 40px;
      padding: 25px;
      p {
        font-size: 22px;
      }
    }
    .header {
      @extend %flex-row-aligned;
      margin: 0px;
      font-family: $headingFont;
      font-weight: bold;
      line-height: 1.3;
      @media screen and (min-width: 768px) {
        font-size: 35px;
      }
      svg {
        margin-right: 15px;
        height: 30px;
      }
    }
  }
  .carousel.slide,
  .carousel-item,
  .carousel-inner {
    width: 100%;
    height: 100%;
  }
}
