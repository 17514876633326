@import "../colors.scss";
@import "../mixins.scss";
@import "../typography.scss";
.service-page {
  .content {
    margin: 30px 20px;
    @media screen and (min-width: 768px) {
      margin: 50px 20px;
    }
  }
  p,
  li {
    font-size: 18px;
    margin-bottom: 5px;
  }
  p {
    margin-top: 15px;
    font-family: $bodyFont;
  }
  h1,
  h2,
  h3 {
    font-family: $roboto;
    margin: 15px 0px;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
    margin: 20px 0px;
    color: $darkBlue;
  }
  ul {
    margin-top: 15px;
    margin-left: 30px;
    list-style-type: disc;
  }
  li {
    height: auto;
    font-family: $bodyFont;
  }
  section {
    margin: 60px 0px;
  }
  a {
    color: $layoutOrange;
    &:hover {
      color: $layoutDarkBlue;
    }
  }
}

.vaccines {
  .accordion-header {
    margin: 0;
    button:focus {
      outline: 1px solid $disabledLight;
      box-shadow: unset;
    }
  }
  .accordion-body {
    margin: 0;
    background-color: #d4e1e8;
  }
  .accordion-button:not(.collapsed) {
    color: unset;
    background-color: unset;
  }
}
