@import "../colors.scss";
@import "../mixins.scss";
@import "../typography.scss";
.nav-1 {
  background-color: $layoutDarkBlue;
  img {
    height: 50px;
  }
  .nav-content {
    height: 80px;
    .nav-tab {
      &:hover,
      &.selected {
        text-decoration: underline;
        background-color: $layoutDarkBlue;
        cursor: pointer;
      }
    }
  }
}

.nav-2 {
  background-color: $layoutLightBlue;
  .nav-content {
    height: 60px;
  }
}

nav {
  width: 100%;
  z-index: 2;
  @media screen and (min-width: 768px) {
    position: unset;
    bottom: unset;
    .nav-content {
      max-width: 1300px;
      padding: 0 10px;
    }
  }
  @media screen and (min-width: 991px) {
    .nav-content {
      padding: 0 50px;
    }
  }
  svg {
    color: $layoutWhite;
  }
  .nav-tabs {
    @media screen and (min-width: 768px) {
      ul {
        display: flex;
        height: 100%;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
    border: none;
    .nav-tab {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 52px;
      width: 100%;
      padding: 5px 40px;
      border-bottom: 0.5px solid black;
      color: $layoutWhite;
      font-family: $quicksand;
      > span,
      > a > span {
        font-size: 16px;
      }
      > svg,
      > a > span {
        margin-right: 0px;
      }
      @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: unset;
        padding: 0px 15px;
        border-top: unset;
        border-bottom: unset;
        > span,
        > a > span {
          font-size: 16px;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        > span,
        > a > span {
          font-size: 16px;
        }
      }
      @media screen and (min-width: 992px) and (max-width: 1180px) {
        > span,
        > a > span {
          font-size: 14px;
        }
      }
      a {
        @extend %flex-centered;
        height: 100%;
      }
      .tab-content {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        color: $layoutWhite;
      }
      &.selected {
        text-decoration: none;
        background-color: $layoutBlack;
        cursor: pointer;
      }
      &:hover {
        text-decoration: underline;
        cursor: pointer;
        .submenu {
          display: flex;
        }
      }
      .submenu {
        position: absolute;
        display: none;
        flex-direction: column;
        background-color: $layoutLightBlue;
        height: auto;
        top: 100%;
        left: 0;
        li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 225px;
          a {
            padding: 10px 15px;
            width: 100%;
          }
          .tab-content {
            width: 100%;
            height: auto;
            font-family: $roboto;
            font-size: 14px;
          }
        }
        svg {
          margin: 0;
        }
      }
    }
    .level-2 {
      padding-left: 70px;
      @media screen and (min-width: 768px) {
        padding-left: 0px;
      }
    }
    .level-3 {
      padding-left: 100px;
    }
    .no-hover-underline {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
