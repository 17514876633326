button {
  @extend %button-text;
  @extend %flex-centered;
  height: 40px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  font-size: 16px;
  &:disabled {
    color: $disabledLight;
  }
  svg {
    height: 20px;
  }
}

.btn-text-primary,
.btn-text-secondary,
.btn-text-tertiary {
  height: auto;
  justify-content: start;
  &:hover:not(:disabled) {
    text-decoration: underline;
  }
  &:disabled {
    color: $disabledLight;
  }
}

.btn-text-primary {
  color: $attention;
  &:hover:not(:disabled) {
    color: $interactiveGreen3;
  }
}

.btn-text-secondary {
  color: $layoutLightBlue;
  &:hover:not(:disabled) {
    color: $layoutDarkBlue;
  }
}

.btn-text-tertiary {
  color: $layoutOrange;
  &:hover:not(:disabled) {
    color: $layoutOrange;
  }
}

.btn-rounded,
.btn-rounded-primary,
.btn-rounded-secondary,
.btn-rounded-tertiary {
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  &:disabled {
    color: #a8a8a8;
    background-color: $disabledBackground;
    border: 1px solid #a8a8a8;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.btn-rounded-primary {
  color: $interactiveGreen3;
  border: 1px solid $interactiveGreen3;
  &:hover:not(:disabled) {
    color: $layoutWhite;
    background-color: $interactiveGreen3;
  }
}

.btn-rounded-secondary {
  color: $layoutLightBlue;
  border: 1px solid $layoutLightBlue;
  &:hover:not(:disabled) {
    color: $layoutWhite;
    background-color: $disabledDark;
  }
}

.btn-rounded-tertiary {
  color: $layoutWhite;
  background-color: $layoutOrange;
  &:hover:not(:disabled) {
    background-color: $disabledLight;
  }
}

.btn-filled-primary,
.btn-filled-secondary,
.btn-filled-tertiary {
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  &:disabled {
    color: $layoutWhite;
    background-color: $subtle;
  }
}

.btn-filled-primary {
  color: $layoutWhite;
  background-color: $layoutLightBlue;
  &:hover:not(:disabled) {
    background-color: $layoutDarkBlue;
  }
}

.btn-filled-secondary {
  color: $layoutWhite;
  background-color: $disabledLight;
  border: 1px solid $disabledLight;
  &:hover:not(:disabled) {
    color: $layoutWhite;
    background-color: $disabledDark;
    border: 1px solid $disabledDark;
  }
}

.btn-filled-tertiary {
  color: $layoutWhite;
  background-color: $layoutOrange;
  &:hover:not(:disabled) {
    background-color: $disabledLight;
    border: 1px solid $disabledDark;
  }
}
