/* cuprum-regular - latin */

@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Cuprum";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/cuprum/cuprum-v25-latin-regular.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("../fonts/cuprum/cuprum-v25-latin-regular.ttf") format("truetype");
  /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* quicksand-300 - latin */

@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/quicksand/quicksand-v31-latin-300.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("../fonts/quicksand/quicksand-v31-latin-300.ttf") format("truetype");
  /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* sura-regular - latin */

@font-face {
  font-family: "Sura";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/sura/sura-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/sura/sura-v13-latin-regular.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/sura/sura-v13-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/sura/sura-v13-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/sura/sura-v13-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/sura/sura-v13-latin-regular.svg#Sura") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-regular - latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto/roboto-v29-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto/roboto-v29-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto/roboto-v29-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/roboto/roboto-v29-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/roboto/roboto-v29-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/roboto/roboto-v29-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

$headingFont: "Cuprum";
$bodyFont: "Quicksand";
$cuprum: "Cuprum";
$quicksand: "Quicksand";
$roboto: "Roboto";
$sura: "Sura";
%header-text {
  font-weight: bold;
  letter-spacing: 0.05em;
}

%button-text {
  font-size: 16px;
  line-height: 16px;
}

%message {
  font-size: 16px;
  font-family: $bodyFont;
}

.heading-font {
  font-family: $headingFont !important;
}

.body-font {
  font-family: $bodyFont !important;
}

.roboto {
  font-family: $roboto !important;
}

.quicksand {
  font-family: $quicksand !important;
}

.sura {
  font-family: $sura !important;
}

.bold {
  font-weight: bolder !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}
